import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { UserService } from '@core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Observable, catchError, first, throwError } from 'rxjs';

export const authInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const userService = inject(UserService);

    // Clone the request object
    let newReq = req.clone();

    if (
        authService.accessToken &&
        !AuthUtils.isTokenExpired(authService.accessToken)
    ) {
        userService.user$
            .pipe(
                first() // Wait for the first emission of the user observable
            )
            .subscribe((user) => {
                const shopId = user ? user?.shopId : '';
                newReq = req.clone({
                    headers: req.headers
                        .set(
                            'Authorization',
                            'Bearer ' + authService.accessToken
                        )
                        .set('Shop', shopId),
                });
            });
    }

    // Response
    return next(newReq).pipe(
        catchError((error) => {
            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
                // Sign out
                authService.signOut();

                // Reload the app
                location.reload();
            }

            return throwError(error);
        })
    );
};
