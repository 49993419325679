<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
@if (layout === 'empty') {
    <empty-layout></empty-layout>
}

@if (layout === 'classy') {
    <classy-layout></classy-layout>
}
